import Swiper, { Pagination, Navigation, Autoplay } from "swiper";

Swiper.use([Pagination, Navigation, Autoplay]);

const swipers = document.body.querySelectorAll(".swiper-container");
swipers.forEach((swiper, i) => {
  const next = swiper.querySelector(".swiper-button-next");
  const prev = swiper.querySelector(".swiper-button-prev");
  const pagination = swiper.querySelector(".swiper-pagination");
  new Swiper(".quote-hero-swiper", {
    pagination: {
      el: pagination,
      type: "bullets",
    },
    navigation: {
      nextEl: next,
      prevEl: prev,
    },
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
    },
    grabCursor: true,
  });
});
